//
//
//
//
//
//
//
//
//
//
//

import stepMixin from '../lib/step-mixin.js';
import StepLayout from './step-layout.vue';

export default {
  components: {
    StepLayout
  },
  mixins: [stepMixin],
  data: () => ({})
};
